
<!-- not in use, maybe to be deleted -->

<script setup>
    import { ref, provide, onMounted } from "vue";
    import MContainer from "mobile.vue.components.Container.vue";
    import MContent from "mobile.vue.components.Content.vue";
    import MSearch from "mobile.vue.components.Search.vue";

    const props = defineProps({
        dataObject: { type: Object },
        multiselect: { type: Boolean },
        noSearch: { type: Boolean },
        focusSearch: { type: Boolean, required: false },
    });
    const emit = defineEmits(["select", "search"]);

    const search = ref("");

    function onClick(row) {
        if (row.Disabled) { return; }
        if (props.multiselect) {
            row.isSelected = !row.isSelected;
        } else {
            emit("select", row);
        }
    }

    function onSelect() {
        emit("select", props.dataObject.data.filter(e => e.isSelected));
    }

    function onSearch() {
        emit("search", search.value);
    }

    provide("ListView", {
        props: props,
        onClick,
    });
</script>

<template>
    <MContainer :show-spinner="dataObject.state.isLoading">
        <div class="mt-2 px-2 mb-2" v-if="!noSearch">
            <MSearch v-model="search" @search="onSearch" style="height: 2.5rem;" />
        </div>
        <template v-if="$slots.pills">
            <div class="px-2 mb-2 d-flex gap-2">
                <slot name="pills" />
            </div>
        </template>
        <MContent :data-object="props.dataObject">
            <slot />
        </MContent>
        <template v-if="multiselect">
            <div class="p-2">
                <button class="btn btn-outline-primary w-100" @click="onSelect">
                    {{ $t("Select") }}
                </button>
            </div>
        </template>
    </MContainer>
</template>
